input[type='range'] {
	-webkit-appearance: none;
    height: 20px;
    width: 200px;
	border-radius: 4px;
	cursor: pointer;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 30px;
        height: 20px;
		border-radius: 4px;
		cursor: pointer;
	}
}