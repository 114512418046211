@import './pvper.var.scss';
@import '~bulma/bulma';
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.media-content.character-name {
	flex-grow: 0;
	padding-right: 1em;
}

.media-content.character-info {
	flex-grow: 0;
	padding-left: 1em;
}

.has-right-border {
	border-right: solid 1px whitesmoke;
}

.progress.is-pvp-rating::-webkit-progress-bar {
	background-color: $danger;
}

.progress.is-pvp-rating::-webkit-progress-value {
	background-color: $success;
}
.color-death-knight {
	color: #c41f3b;
}
.color-demon-hunter {
	color: #a330c9;
}
.color-druid {
	color: #ff7d0a;
}
.color-hunter {
	color: #abd473;
}
.color-mage {
	color: #69ccf0;
}
.color-monk {
	color: #00ff96;
}
.color-paladin {
	color: #f58cba;
}
.color-priest {
	color: #ffffff;
}
.color-rogue {
	color: #fff569;
}
.color-shaman {
	color: #0070de;
}
.color-warlock {
	color: #9482c9;
}
.color-warrior {
	color: #c79c6e;
}

table td {
	vertical-align: middle !important;
}

.has-text-vertical-middle {
	vertical-align: middle;
}

.has-background-horde-color {
	background-color: $color-dark-horde;
}

.has-background-alliance-color {
	background-color: $color-dark-alliance;
}

tr.faction-horde:hover {
	background-color: $color-dark-horde;
}

tr.faction-alliance:hover {
	background-color: $color-dark-alliance;
}

header {
	font-weight: bold;
	font-family: Arial;
	font-size: 24px;
	img {
		&.brand-image {
			padding-right: 0.3rem;
		}
	}
	span {
		letter-spacing: 0px;

		&.brand-first {
			color: $pvper-red;
		}
		&.brand-second {
			color: $pvper-blue;
		}
		&.brand-third {
			color: $primary-light;
		}
	}
	.navbar-item.nav-home {
		border: 3px solid transparent;
		border-radius: 0%;
		&:hover {
			border-right: 3px solid $primary-light;
			border-left: 3px solid $primary-light;
		}
	}
}

.is-24x20 {
	width: 24px;
	height: 20px;
}
